<template>
  <footer class="bg-gradient-to-b from-white to-gray-50 text-gray-900 py-20 ">
    <div class="max-w-7xl mx-auto px-8 ">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
        <div v-for="(section, index) in footerSections" :key="index">
          <h3 class="text-xl font-semibold mb-6">
            {{ section.title }}
          </h3>
          <template v-if="section.type === 'text'">
            <p class="text-gray-600 mb-6">
              {{ section.content }}
            </p>
            <div v-if="section.socialLinks" class="flex space-x-4">
              <a
                v-for="(link, linkIndex) in section.socialLinks"
                :key="linkIndex"
                href="#"
                class="text-gray-600 hover:text-blue-600"
              >
                <i :class="link.icon + ' text-xl'" />
              </a>
            </div>
          </template>
          <ul v-else class="space-y-4">
            <li v-for="(item, itemIndex) in section.items" :key="itemIndex">
              <template v-if="typeof item === 'string'">
                <a :href="item.url" class="text-gray-800 hover:text-blue-600">{{ item }}</a>
              </template>
              <template v-else>
                <div class="flex items-center text-gray-600">
                  <i :class="item.icon + ' mr-3'" />
                  <a :href="item.url" target="_blank" class="text-gray-800 text-sm hover:text-blue-600">{{ item.text }}</a>
                </div>
                <img v-if="section.id === '4'" :src="section.img" alt="服务二维码" class="w-24 h-24 mt-4">
              </template>
            </li>
          </ul>
        </div>
      </div>
      <div class="border-t border-gray-200 mt-12 pt-8">
        <div class="flex flex-col space-y-4">
          <div class="flex items-center space-x-4 justify-start">
            <span class="text-gray-600 text-sm">合作伙伴</span>
            <a
              href="https://www.bt.cn/u/fwvsEi"
              target="_blank"
              class="flex items-center space-x-2"
            >
              <img src="https://www.bt.cn/static/new/images/logo_03.svg" alt="宝塔面板" class="h-6">
              <span class="text-gray-600 text-sm">宝塔服务器面板，一键全能部署及管理</span>
            </a>
          </div>
          <div class="flex flex-col md:flex-row justify-between items-center">
            <div class="text-gray-600 text-left space-y-2 text-sm">
              <p>Copyright © 2019 - 2025 TDuckCloud. 湖南省众达数蔚信息技术有限公司- <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备19005498号-3</a></p>
              <p>All Rights Reserved. 填鸭表单版权所有   <a href="https://beian.miit.gov.cn/" target="_blank">湘公网安备43058102000053号</a></p>
            </div>
            <div class="flex space-x-6 mt-4 md:mt-0">
              <a
                v-for="(link, index) in footerLinks"
                :key="index"
                href="#"
                class="text-gray-600 hover:text-blue-600"
              >{{ link }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const footerSections = [
  {
    id: '1',
    title: '关于我们 | About Us',
    type: 'text',
    content: '填鸭表单致力于为企业和个人提供专业的在线问卷调查解决方案',
    socialLinks: [
      { icon: 'fab fa-weixin' },
      { icon: 'fab fa-weibo' },
      { icon: 'fab fa-github' }
    ]
  },
  {
    id: '2',
    title: '产品 | Products',
    items: [{
      text: 'TDuckX-Vue3旗舰版',
      url: 'https://x.tduckcloud.com'  
    }, {
      text: 'TDuckPro-Vue2专业版',
      url: 'https://pro.tduckcloud.com'
    }, {
      text: 'Nium-智能二维码',
      url: '/nium'
    }, {
      text: 'TReport可视化大屏',
      url: 'https://report.tduckcloud.com'
    }, {
      text: 'TDuck社区版',
      url: 'https://demo.tduckapp.com'
    }
  ]
  },
  {
    id: '3',
    title: '支持 | Support',
    items: [{
      text: '文档中心',
      url: 'https://doc.tduckcloud.com/'
    }, {
      text: 'TDuckLife',
      url: 'https://space.bilibili.com/409825300/video'
    }, {
      text: '公益组织支持计划',
      url: 'https://x.tduckcloud.com/s/7wzfTiuG'
    }, {
      text: 'Pig微服务',
      url: 'https://www.pig4cloud.com/'
    }, {
      text: '交换友链',
      url: 'https://x.tduckcloud.com/s/3c1CjVYX'
    }
  ]
  },
  {
    id: '4',
    title: '联系我们 | Contact Us',
    items: [
      // { icon: 'fas fa-phone-alt', text: '400-888-8888' },
      { icon: 'fas fa-envelope', text: 'mama@tduckapp.com' },
      // { icon: 'fas fa-map-marker-alt', text: '北京市朝阳区望京 SOHO T1' }
    ],
    img: 'https://minio.tduckcloud.com/landing/images/serviceQrCode.png'
  }
]

const footerLinks = ['隐私政策', '服务条款', '法律声明']
</script> 